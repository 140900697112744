<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="選んでください" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>プライバシーポリシー</h2>
		<p>
			はじめに<br />
			私たちは個人情報があなたにとって重要であることを深く知っていて、法律法規の要求に厳格に遵守して相応の安全保護措置を取って、あなたの個人情報の安全を保障することに力を入れて制御することができます。PIX TECHNOLOGY
			PTE.
			LTD社下のUniLiveプラットフォームサービスの前に、本基本機能プライバシーポリシー（以下「本プライバシーポリシー」）をよく読んで理解して、適切な使用方法を選択してください。<br />
		</p>
		<p>
			このプライバシーポリシーは、Webサイト、Webページ、クライアント、アプレット、技術の発展に伴って出現した新しいサービスキャリアなど、UniLiveソフトウェアのサービスにのみ適用されます。
		</p>
		<p>
			このプライバシーポリシーについて何か質問がある場合は、このプライバシーポリシーが「どのように私たちに連絡するか」を明記した方法で私たちに連絡することができます。私たちは喜んであなたに答えます。私たちも法律法規と業務発展の変化に基づいて、時々本プライバシーポリシーを改訂し、適切な方法でお知らせします。
		</p>
		<p>
			14歳未満18歳未満の未成年者または知的、精神的健康状態のために完全な民事行為能力を持たない場合は、法定保護者の指導と付き添いの下で本プライバシーポリシーを共同で読んで同意してください。14歳未満の未成年の場合は、法定保護者が本プライバシーポリシーを読んで同意してください。あなたとあなたの法定保護者は特に未成年者の保護内容に注意してください。
		</p>
		<h3>このプライバシーポリシーは、次のことを理解するのに役立ちます</h3>
		<p>一、個人情報の収集と使用</p>
		<p>二、個人情報の共有、移転、公開</p>
		<p>三、個人情報の保護と保存</p>
		<p>四、あなたの権利</p>
		<p>五、未成年者保護</p>
		<p>六、プライバシーポリシーへのアクセスと更新</p>
		<p>七、連絡方法</p>
		<h3>一、個人情報の収集と使用</h3>
		<p>
			UniLiveは一定時間を超えない動画検索、再生の短い動画サービスを提供しており、本プライバシーポリシーに同意しない場合は、「同意しない」をクリックしてゲストモードに入ることができます。
		</p>
		<p>
			さらに豊富な製品やサービスを提供します。お客様が選択して使用する機能および/またはサービスを実現し、法規制の要件を遵守するためには、適切な個人情報を収集し、使用し、対応する必要な権限を有効にする必要があります。拒否すると、機能やサービスを使用できなくなる可能性がありますが、他の非関連機能やサービスの正常な使用には影響しません。このプライバシーポリシーの第4部「お客様の権利」を使用して、個人情報および権限管理を行うことができます。
		</p>
		<p>
			この基本機能プライバシーポリシーに同意する場合は、UniLive基本機能の使用に同意した場合にのみ、必要な個人情報を処理します。UniLiveの他の拡張機能を使用する場合は、具体的な拡張機能を使用する際に別途同意を求めます。
		</p>
		<p>
			特定のシーンでは、即時告知（ポップアップ、ページプロンプト）、機能更新の方法で、対応する情報収集の目的、範囲、使用方法についても説明します。このような説明は、本プライバシーポリシーの一部を構成し、本プライバシーポリシーと同等の効力を有する。
		</p>
		<p>
			通常、次のUniLiveプラットフォーム機能および/またはサービスを実装するために、個人情報を収集して使用したり、関連する権限を申請したりします。
		</p>
		<p>
			1.アカウントサービス
		</p>
		<p>
			私たちの豊富な機能および/またはサービス（コメント、いいね、コレクション、ビデオの投稿）を使用するためには、UniLiveプラットフォームに登録、ログインするために関連する情報を提供する必要があります。
		</p>
		<p>
			1.1電子メール：電子メールボックスを使用してUniLive製品とサービスにログインするには、電子メールアドレス、パスワードを必要な情報として提供する必要があります。
		</p>
		<p>
			2.コンテンツの閲覧、再生、検索
		</p>
		<p>
			2.1コンテンツの閲覧と再生
			UniLiveプラットフォームのコンテンツを閲覧、再生する際には、デバイス情報（OAID、AndroidID、IDFA）と個人のインターネット記録（閲覧、クリック、共有、コレクション）を記録し、連続性、一貫性のある使用体験を提供し、サービスの質を保証するために使用します。
		</p>
		<p>
			2.1.1ロケーション関連ブラウズ：同都市サービスを使用する場合、正確な地理的位置情報を収集、使用するためのロケーションオープン権限を申請します。正確な位置情報を許可しないと、測位の正確性に影響を与える可能性があります。私たちは粗い位置情報（WLANアクセスポイント（SSID、BSSID）、Bluetoothと基地局、センサー情報、IPアドレス情報、これらの情報と正確な地理位置情報を組み合わせた位置情報）、および関連する機能および/またはサービスで提供されている位置情報（アカウント資料に自主的に記入された所在地、アップロードされたコンテンツに追加された位置リンク）を使用して、トップページ、精選、発見、注目機能を使用する際に、上記の位置情報に関連するコンテンツおよび/または付加価値サービスを表示するためのあなたのおおよその位置を判断します。
		</p>
		<p>
			2.1.2横画面再生：横画面モードを使用する場合、加速度、重力、方向センサーを呼び出し、センサー情報を通じて設備の画面方向の変化を検出し、横縦画面の切り替えを行う必要があります。
		</p>
		<p>
			2.2検索
		</p>
		<p>
			検索機能を使用すると、デバイス情報（OAID、AndroidID、IDFA）、コマンド（テキスト、音声、または画像）およびログ情報を検索します。効率的な検索サービスを提供するために、キーワード情報は一時的にローカルデバイスに保存され、検索アイコンをクリックすると表示され、その検索履歴をすべてまたは一部削除することもできます。
			スキャンによってQRコードを識別したり、商品を検索したり、画像検索を行ったりする場合は、カメラ、アルバムを開く権限を申請します。写真を撮ったり、アルバムを通じて画像をアップロードしたりして検索を実現することができます。スイープを使用する場合は、リニア加速度センサーを呼び出してデバイスの方向と角度情報を検出し、スイープの内容を認識するのに役立ちます。関連権限のオープンに同意しない場合は、基本的な機能の使用には影響しません。
		</p>
		<p>3.
			ソーシャル・インタラクション</p>
		<p>
			私たちはあなたに豊富な社交的なインタラクティブ機能を提供しました。より多くの友人との迅速なコミュニケーションを支援するために、次の手順に従って情報を収集して使用します。
		</p>
		<p>
			3.1コンテンツの相互作用
		</p>
		<p>
			インタラクティブなコミュニケーションを行う際には、インタラクティブな記録とコンテンツ（閲覧、いいね、コメント、注目、コレクション、転送、共有、および@他のユーザー）を収集し、公開します。
		</p>
		<p>
			共有されたコンテンツ、イベント情報を共有または受信する場合、UniLiveは切断ボードにアクセスし、パスワード、共有コード、リンクを読み込んで、ジャンプ、共有、福利厚生、イベント連動サービスを実現し、ユーザーが関連コンテンツを開き、ユーザー体験を向上させるのを支援する必要があります。切断ボードのコンテンツをローカルに識別し、UniLiveコマンドに属している場合にのみサーバをアップロードします。また、ビデオや画像を共有したり保存したりする際には、アルバムを読み込む必要があります。
		</p>
		<p>
			一部のデバイスでは、クリップボードやアルバムを読み込んでいることをアプリケーションに促すことがありますが、これらのシステムはローカル処理かアップロードかを区別しないと判断し、異なるデバイスの統計基準の違いにより誤報の可能性があります。何か質問があれば、私たちに連絡してください。
		</p>
		<p>
			3.2メッセージ通知
		</p>
		<p>
			UniLiveは、許可を得て収集した携帯電話番号、メールボックスに基づいて、メール、電話、電子メール、システム通知を通じて、興味がある可能性があるおよび/または必要な関連コンテンツ、イベント、サービス、機能情報を提供し、身分証明書、セキュリティ検証、ユーザー使用体験調査を支援することを知って同意します。関連するPush情報を受け入れたくない場合は、このプライバシーポリシーの第4部「エンタイトルメント」で提供されるパスを使用して関連設定を行うことができます。
		</p>
		<p>4.
			コンテンツの撮影、作成、配布</p>
		<p>
			4.1撮影、製作
		</p>
		<p>
			ショートビデオ、ライブツールを使用して音声ビデオ、画像コンテンツを撮影、作成する場合は、写真のアップロードコンテンツとローカル同期ストレージを実現して作品の紛失を防止し、マイク権限で音声の録音を行うためのカメラ権限をオンにする権限を申請します。アップロードを選択した音声ビデオ、画像の内容だけをアップロードします。他の情報はアップロードしません。同時に、光線を監視し、明るさを調整し、撮影画面の角度調整を実現するために、私たちはあなたの設備に光線センサー、加速センサーを呼び出します。このプロセスでは、すべてまたは一部の権限を付与することを選択できます。関連権限のオープンに同意しない場合は、基本的な機能の使用には影響しません。
		</p>
		<p>
			4.2リリース
		</p>
		<p>
			画像、ビデオコンテンツを公開する場合は、関連コンテンツを収集し、顔、ニックネーム、および上記のコンテンツ情報を公開します。
		</p>
		<p>
			4.2.1クイックアップロード：投稿する作品のアップロード速度を向上させ、豊富なリリース機能、最適化体験、およびトラフィックを節約するために、撮影が完了したら「リリース」ボタンをクリックする前に、このコンテンツをデバイスがWLANに接続されている状態でアップロードを開始し、サーバに一時的にロードして保存します。ただし、この機能では事前に作品をリリースすることはありません。「リリース」ボタンをクリックしてからリリースを提出することができます。パブリケーションをキャンセルしたり、他の理由でアップロードに失敗したりした場合は、すぐに関連コンテンツを削除します。この機能は、このプライバシーポリシーのパート4である「エンタイトルメント」が提供するパスを使用して閉じることができます。
		</p>
		<p>5.
			パーソナライズ推奨</p>
		<p>
			5.1パーソナライズ推奨サービス
			関連性の高いコンテンツを表示し、推薦し、より適切なサービスを提供するために、UniLiveプラットフォームサービスを使用する際に閲覧、再生、検索記録を収集し、法に基づいて収集したデバイス情報（OAID、AndroidID、IDFA）、好みの特徴に基づいて、興味のあるページ、ビデオ、ライブコンテンツ、検索結果、知り合い、コマーシャル、サービス、機能をプッシュするためのログ情報、および他の承認された情報。位置情報に基づいてトップページ、ライブ、ショッピングモールのページで個人的な推奨を行うことを許可されている場合は、適切な機能で位置情報を収集して個人的な推奨を行います。
		</p>
		<p>
			5.2パーソナライズされた推奨実行メカニズム
		</p>
		<p>
			パーソナライズされた推奨事項は、アルゴリズムモデルに基づいてお客様の好みの特徴を予測し、お客様が興味を持っている可能性のあるコンテンツ、サービス、その他の情報と一致し、お客様に提示された情報をソートします。お客様がUniLiveプラットフォームサービスを利用している間の個人的なインターネット記録に基づいて、推奨モデルをリアルタイムでフィードバックし、最適化推奨結果を調整し続けます。多様なニーズに対応するために、並べ替えの過程で多様な推薦技術を導入し、同じ種類のコンテンツが過度に集中しないように推薦コンテンツを拡大します。
		</p>
		<p>6.
			カスタマーサービス</p>
		<p>
			お客様にカスタマーサービスを提供するためには、アカウントのセキュリティを保証するために必要な個人情報を認証する必要がある場合があります。お客様との連絡を容易にし、問題を解決したり、関連する問題の処理方法や結果を記録したりするために、連絡先（お客様がお客様と連絡を取る際に使用する電話番号/電子メールボックス、またはお客様が積極的に提供する他の連絡先）、お客様とのコミュニケーション情報（お客様とお客様の文字/画像/音声ビデオ/通話記録）、その他の必要な情報（アカウント情報、注文情報、お客様が関連する事実を証明するために提供する他の情報）を収集することができます。
		</p>
		<p>
			サービスを提供し、サービス品質を改善するための合理的なニーズのために、カスタマーサービスに連絡する際に提供される関連情報、アンケートに参加する際に送信されるアンケート回答情報を使用することもできます。
		</p>
		<p>7.
			APPの適応と運営の安全保障</p>
		<p>
			7.1優れた信頼性のあるコンテンツ、サービスを提供し、製品と機能の正常な安定した運行を維持し、不正行為、詐欺を防止することによって、あなたまたは他のユーザーまたは公衆の安全と合法的な利益を侵害から保護し、悪意のあるプログラム、フィッシングサイト、ネットワーク脆弱性、コンピュータウイルス、サイバー攻撃、ネットワーク侵入の安全リスクをより正確に予防し、法律法規またはUniLiveプラットフォームの関連規則に違反した状況をより正確に識別するために、私たちはあなたの設備情報、ログ情報、センサー情報、および他のあなたが授権した情報（法律に基づいてあなたの授権を取得した後、パートナーから収集した情報）を収集、使用して統合し、アカウントの安全、取引の安全を判断し、身分検証を行い、違法な違反状況を識別し、安全事件をそして法に基づいて必要な記録、分析、処置措置。インストールしたアプリケーション情報（アプリケーションパッケージ名）または実行中のプロセス情報、アプリケーションの全体的な実行、使用状況と頻度、アプリケーションクラッシュ、全般的なインストール使用状況、パフォーマンスデータ、アプリケーションソースにアクセスし、非常にAppを参照したパッケージ名情報のみをアップロードすることができます。
		</p>
		<p>
			デバイス情報：デバイス識別情報（AndroidID、IDFA、IDFV、UAID（チャイナモバイルUAID、チャイナユニコムUAID及びチャイナテレコムUAID、アンドロイドデバイスのみ）、OAID、Openudid及びその他の統合デバイスパラメータとシステム情報からなるデバイス識別子、異なる識別子は有効期間、ユーザーによるリセット可否及び取得方法及びシステムによって異なる）、ネットワーク関連情報（Bluetooth、IPアドレス、ネットワーク事業者、ネットワーク状態、タイプ、アクセス方法、ネットワーク品質データ）、デバイスパラメータ情報（デバイス名、デバイスモデル）、ソフトウェアハードウェアオペレーティングシステム情報（本アプリケーションバージョン、オペレーティングシステム、言語、解像度）を選択します。
		</p>
		<p>
			ログ情報：操作、使用した個人のインターネット記録（閲覧、再生、検索、いいね、フォロー、コレクション、コメント、ダウンロード、共有、取引）。
		</p>
		<p>
			UniLiveをデバイスのバックグラウンド実行に切り替えると、一部のデバイスモデルやシステムの理由で、UniLive関連機能を使用して以前に発生した情報収集動作がすぐに停止できず、一時的なバックグラウンド情報収集動作につながる可能性があることを理解してください。
		</p>
		<p>
			7.2運用の品質と効率を向上させるために研究、統計分析を行う場合、私たちは上記の個人情報を識別化処理し、あなたの正体とは関連しません。上記の非識別子化情報は、UniLiveプラットフォームのコンテンツとレイアウトを改善し、ビジネス意思決定のための製品またはサービスのサポートを提供し、プラットフォーム上で提供する製品とサービスを改善するために使用されます。
		</p>
		<p>8.
			個人情報を第三者から間接的に収集する</p>
		<p>
			私たちは第三者から個人情報を取得することはありません。関連する機能とサービスを実装するために第三者から個人情報を取得する必要がある場合は、第三者が関連する個人情報について権限を取得しているか、第三者が法に基づいて許可されているか、個人情報を開示するように要求されていることを確認します。サービスを展開するために必要な個人情報処理活動が、第三者に個人情報を提供していたときの権限の範囲を超えている場合は、明確な同意を得ます。
		</p>
		<p>9.
			Cookie及び同種技術</p>
		<p>
			Cookieと同種の機器情報識別技術は、インターネットで一般的に使用されている技術です。デルのサービスを使用する場合、UniLiveプラットフォームサービスを使用しているときの情報を収集し、識別するために、関連技術を使用してデバイスにCookieまたは匿名識別子（以下「Cookie」）を1つ以上送信することがあります。
			Cookieの使用は主に製品機能とサービスの安全、効率的な運転を保障するためであり、私たちにあなたの口座と取引の安全状態を確認させ、崩壊、遅延に関する異常状況を調査させる、フォームへの再入力、検索内容の入力などの手順やプロセスを省略して、より簡単な使用体験を得ることができます。また、上記のテクノロジーを使用して、お客様の興味のあるコンテンツを推薦、展示、プッシュし、コンテンツの選択とインタラクションを最適化することもできます。私たちは、Cookieを本プライバシーポリシーで説明された目的以外の用途には使用しないことを約束します。
			自分の好みに合わせてCookieを管理または削除できます。ほとんどのブラウザでは、ブラウザのキャッシュデータを消去する機能が提供されており、ブラウザ設定でCookieデータを消去する操作ができます。また、ソフトウェア内に保存されているすべてのCookieを消去することもできます。ただし、パージ後にCookieに依存して提供されている機能やサービスを使用することはできない場合があります。
		</p>
		<p>10.
			サードパーティ製SDKおよびAPI技術</p>
		<p>
			一部の機能および/またはサービスは、サードパーティ製SDK（またはAPI）テクノロジーを使用する必要があります。これらの第三者は
			SDK（またはAPI）は、個人情報の一部を収集または使用することができます。SDK（またはAPI）に関する厳格なセキュリティチェックを行い、技術的な手段を講じて、本プライバシーポリシーおよびその他の関連する機密およびセキュリティ対策に基づいて個人情報を処理できるように努力します。また、ユーザー情報を共有している企業、組織、個人に対しても、ユーザー情報の使用を規範化するために契約を締結します。
		</p>
		<p>11.
			同意取得の例外</p>
		<p>
			法律法規の規定により、以下の場合、個人情報を処理するには同意を得る必要はありません：<br />
			（1）当事者としての契約を締結、履行するために必要、または法に基づいて制定された労働規則制度と法に基づいて締結された集団契約に基づいて人的資源管理を実施するために必要、＜br />
			（2）法定職責又は法定義務を履行するために必要である。<br />
			（3）突発的な公衆衛生事件に対応し、または緊急時に自然人の生命健康と財産の安全を保護するために必要である。<br/>
			（4）公共利益のために新聞報道、世論監督行為を実施し、合理的な範囲内であなたの個人情報を処理する。<br />
			（5）法律、行政法規に規定されたその他の状況。
		</p>
		<h3>二、個人情報の共有、移転、公開</h3>
		<p>
			このプライバシーポリシーで宣言されている目的を達成するためには、次のシナリオでは、関連会社または第三者パートナー（サービスプロバイダ、ネットワーク事業者、デバイスメーカー、その他のパートナー）と個人情報を共有する必要があります。デルは、合法的、正当、必要な原則を遵守し、パートナーの処理目的、安全保障能力を慎重に評価し、法律の規定に基づいて関連協定に署名します。同時に、パートナーに対して厳格な監督と管理を行い、違反や違約行為が発見されたら、すぐに協力を停止し、法的責任を追及します。
		</p>
		<p>関連コラボシーンは以下の通り。</p>
		<p>1.
			関連する機能とサービスを実現するために</p>
		<p>1.1ログインおよびソーシャルサービス：サードパーティの登録、ログイン、共有サービスを提供するために、ログインおよびソーシャルサービスパートナー（メールボックス）はデバイス情報（OAID、AndroidID、IDFA）。
		</p>
		<p>
			1.2プラットフォームのサードパーティ事業者：サードパーティ事業者での商品/サービスの購入、商品/サービスの予約の実現、サードパーティ事業者の割引情報のカスタマイズ、またはアフターサービスの提供を支援するために、プラットフォームのサードパーティ事業者と注文や取引に関する情報、アカウントID情報を共有します。
		</p>
		<p>1.3支払サービス：取引の安全を保障し、商品/サービスの支払いを完了するために、デルが協力する第三者支払機関は、お客様の注文情報、設備情報（OAID、AndroidID、IDFA）。</p>
		<p>1.4物流サービス：商品（購入またはイベントに参加して受け取った商品）の納品と配送を正確、安全に行うために、第三者物流会社は出荷と配送の段階であなたの配送情報を知ることは避けられません。</p>
		<p>
			1.5メッセージ通知：使用しているデバイスに適したメッセージ送信機能を提供するために、デバイスメーカー（ファーウェイ、小米、OPPO、VIVO）はデバイスのモデル、バージョン、関連デバイス情報（OAID、AndroidID、IDFA）。
		</p>
		<p>
			1.6カスタマーサービス：お客様の苦情、アドバイス、その他の要求をタイムリーに処理するために、カスタマーサービスプロバイダはお客様のアカウントと関連情報を使用し、お客様のニーズに常に理解、処理、応答する必要があります。必要に応じて、携帯電話の番号を使用したり、他の通信手段で連絡を取ったりする必要がある場合もあります。
		</p>
		<p>2.
			広告</p>
		<p>
			私たちのビジネスモデルに基づいて、広告のプッシュと投入を行います。広告のプッシュアンドドロップを実現し、広告の効果、有効性を評価、分析、最適化、測定するのに役立てるためには、広告主、サービスプロバイダ、サプライヤのサードパーティパートナー（「広告パートナー」と呼ばれる）と情報とデータを共有する必要がある場合があります。私たちはデータを識別化、匿名化、暗号化アルゴリズムの技術的措置を採用して処理します。
		</p>
		<p>
			2.1広告のプッシュ・アンド・ドロップ：広告のプッシュ・アンド・ドロップを行う場合、広告パートナーは、識別または匿名化されたデバイス情報（OAID、AndroidID、IDFA）、ラベル情報、または広告パートナー（事業者）から使用された識別または匿名化されたデバイス情報（OAID、AndroidID、IDFA）、ラベル情報は、合法的に収集された他のデータと結合して、広告のプッシュと投入の有効なタッチ率を最適化し、向上させることができます。
		</p>
		<p>
			2.2広告統計分析：広告統計分析と最適化サービスを提供する広告パートナーに、識別解除または匿名化されたデバイス情報（OAID、AndroidID、IDFA）、ラベル情報、ログ情報（広告クリック、閲覧、展示）、広告効果変換データは、広告プッシュと配信サービスの有効性を評価、分析、監視、測定し、より適切な広告内容を提供するために使用されます。広告主および/またはその委託サービス事業者は、ユーザーソースの運営分析、広告投入戦略の最適化、製品とサービスの改善のために、前述の部分またはすべての情報を共有することを要求することがあります。
		</p>
		<p>
			2.3広告シーンにおける個人情報の収集とヒント：関連するサードパーティの広告のプッシュとドロップのページに、広告主またはその依頼した広告パートナーによって収集処理される可能性のある適切な個人情報（連絡先、住所、具体的な情報タイプはページ表示に準拠）を自発的に記入して提出する必要があります。
		</p>
		<p>3.
			セキュリティと統計解析を実現する</p>
		<p>
			3.1使用上の安全保障：アカウントおよび/または財産の安全保障を実現し、当社と当社の関連会社、パートナーサービスの安全性を高めるために、必要な設備情報（OAID、AndroidID、IDFA）、アカウント情報、ログ情報は、アカウントと取引リスクを総合的に判断し、詐欺を回避し、セキュリティイベントの発生を予防し、法に基づいて必要な記録、監査、処置措置をとる。
		</p>
		<p>3.2製品分析：製品および／またはサービスの安定性と適合性を分析するために、分析サービスパートナーは製品サービスログ（製品クラッシュ、フラッシュバックのログ情報）、デバイス情報（OAID、AndroidID、IDFA）。
		</p>
		<p>（二）移行</p>
		<p>
			合併、分立、解散、破産宣告を受けて個人情報を移転する必要がある場合は、受信者の名前や名前、連絡先をお知らせします。私たちは受信者に本プライバシーポリシーの約束義務を引き続き履行するように要求します。受信者が以前の処理目的、処理方法を変更した場合、改めて同意を求めることになります。
		</p>
		<p>（3）公開</p>
		<p>国の法律や規制に従ったり、同意を得たりしない限り、自分で公開していない情報は自発的に公開しません。</p>
		<p>（4）個人情報の共有、移転、公開の例外</p>
		<p>法規制により、次の場合、個人情報の共有、移転、公開に同意する必要はありません。</p>
		<p>1.当事者としての契約を締結、履行するために必要な、または法に基づいて制定された労働規則制度と法に基づいて締結された集団契約に基づいて人的資源管理を実施するために必要な、</p>
		<p>2.法定職責または法定義務を履行するために必要である。</p>
		<p>3.突発的な公衆衛生事件に対応するため、または緊急時に自然人の生命健康と財産の安全を保護するために必要である。</p>
		<p>4.公共の利益のために新聞報道、世論監督行為を実施し、合理的な範囲内で個人情報を処理する。</p>
		<p>5.個人情報保護法の規定に従って、個人が自ら公開したり、合法的に公開したりしたその他の個人情報を合理的な範囲内で処理する。</p>
		<h3>三、個人情報の保護と記憶</h3>

		<p>（一）情報セキュリティシステム保証</p>

		<p>

			お客様の情報セキュリティを保証するために、業界標準に準拠した物理的、電子的、管理的なさまざまなセキュリティ対策（SSL、情報暗号化ストレージ、データセンターへのアクセス制御）を講じて、お客様の情報を保護し、情報が漏洩、毀損、紛失されないようにします。デルには、データを中心としたデータライフサイクルを中心とした業界先進のデータセキュリティ管理システムがあり、組織構築、制度設計、人員管理、製品技術、個人情報保護の影響評価から多次元にわたってUniLiveサービスシステムのセキュリティを向上させています。

		</p>

		<p>（二）情報セキュリティ管理制度</p>

		<p>
			私たちは専門のセキュリティ部門、セキュリティ管理制度、データセキュリティプロセスを構築して、あなたの個人情報の安全を保障します。EMCでは、認証者のみが個人情報にアクセスできるように、厳格なデータ使用とアクセス制度を採用しています。私たちは定期的に人員に対して安全教育と訓練を行い、適時にデータと技術に対して安全監査を行います。
		</p>

		<p>

			同時に、専門のセキュリティ技術と運営チームがメンテナンスを担当し、各種類のセキュリティホールと突発事件にタイムリーかつ効果的に対応し、処理しやすく、関連部門と連携してセキュリティイベントの追跡と打撃を行うためのUniLiveセキュリティ緊急対応センターを設立しました。個人情報セキュリティ・イベントが発生したら、法律・法規の要件に従って、セキュリティ・イベントの基本的な状況と影響の可能性、処置が取られているか、自主的に防止し、リスクを軽減できるアドバイス、救済策をお客様にお伝えします。イベントに関する情報は、メール、手紙、電話、プッシュ通知でお知らせします。個人情報の主体を一つ一つ伝えることが難しい場合は、合理的で効果的な方法で公告を発表します。また、関連する規制当局の要求に応じて、データ漏洩などのセキュリティイベントの処理状況を報告します。

		</p>

		<p>（3）アカウントセキュリティリスク防止</p>

		<p>

			いくつかのサービスを使用する場合、取引先または潜在的な取引先に自分の個人情報（電話、銀行口座情報）を開示することは避けられません。情報が盗まれたり、電気通信ネットワーク詐欺に遭ったりしないように、必要な場合にのみ他人に提供される個人情報を適切に保護してください。第三者の製品やサービスを通じて取引されることを軽々しく信じないでください。

		</p>

		<p>（4）情報ストア</p>

		<p>1.
			保管場所</p>

		<p>
			UniLiveは、中国国内での運営過程で収集・生成された個人情報を法律・法規の規定に従って中国国内に保存する。お客様の個人情報格納場所が中国国内から国外に移転する場合は、法律の規定に厳格に従って実行するか、独自の同意を得ることができます。
		</p>

		<p>2. 保存期間</p>

		<p>一般的に、目的を達成するために必要な時間と法律法規で定められた保存期間内にのみ個人情報を保持し、上記の保存期間を超えた後に、個人情報を削除または匿名化します。</p>

		<p>
			UniLiveプラットフォームがサービスを終了したり、運営を停止したりする場合は、事前に運営停止の通知を送達または公告の形式で通知し、サービスを終了したり、運営を終了した後の合理的な期限内に個人情報を削除したり、匿名化処理を行ったりします。
		</p>



		<h3>四、あなたの権利</h3>

		<p>

			私たちはあなたが個人情報の主体として享受する権利を非常に重視して、そして全力を尽くしてあなたの個人情報に対する制御権を保護します。UniLiveアカウントの登録、ログインが完了すると、関連する権利を以下の方法で行使することができます（関連製品ごとに権利の行使経路が一致しない場合があります。実際の状況に基づいて）、私たちは法律法規の要求に合致した場合にあなたの要求に応答します。また、お客様は、このプライバシーポリシーが「どのように私たちに連絡するか」に記載されている方法で、私たちに連絡して権利を行使することもできます。

		</p>

		<p>（一）個人情報の閲覧、コピー、転送</p>

		<p>UniLiveで個人情報の閲覧、コピー、転送を行うことができます。</p>

		<p>（二）個人情報の訂正・補足</p>

		<p>私たちが処理している個人情報が不正確または不完全であることに気づいた場合、あなたのアイデンティティを検証した後、フィードバックとエラーの報告を通じてあなたの訂正または補充申請を私たちに提出することができます。</p>

		<p>一般的には、いつでも自分が提出した情報を訂正、補充することができますが、セキュリティと認証（メールボックスの訴えサービス）の観点から、登録時に提供された初期登録情報と検証情報の一部は変更可能な列にありません。</p>

		<p>（3）個人情報の削除</p>

		<p>上記の「（2）個人情報の訂正、補足」に記載されているパスを使用して情報の一部を削除するか、アカウントの登録解除を申請して情報のすべてを削除することができます。</p>

		<p>以下の場合、個人情報の削除を依頼することもできます。</p>

		<p>1. 処理目的が達成された、実現できない、または処理目的を実現するために必要ではない、</p>

		<p>2. 製品またはサービスの提供を停止したり、保存期間が満了したりします。</p>

		<p>3. 同意を撤回してください。</p>

		<p>4. 法律法規に違反したり、約束に違反したりして個人情報を処理したりします。</p>

		<p>5. 法律法規に規定されているその他の状況。</p>

		<p>
			EMCのサービスから情報を削除した後、適用される法的およびセキュリティ技術上の制限のため、バックアップ・システムから適切な情報をすぐに削除することはありません。バックアップが消去または匿名化されるまで、ストレージと必要なセキュリティ対策以外の処理を停止します。
		</p>

		<p>（4）同意の撤回及び権限の変更</p>

		<p>

			各機能および/またはサービスには、基本的な個人情報が必要です。同意を撤回した後、同意を撤回した機能および/またはサービスを提供し続けることはできません。また、個人情報を処理することはできません。しかし、同意を撤回する決定は、撤回前に個人同意に基づいて行われた個人情報処理活動の効力に影響を与えません。

		</p>

		<p>（五）アカウントをログアウトする</p>

		<p>

			申請を通じてアカウントをログアウトすることができます。対応するユーザーまたはそのユーザーによって許可されたエージェントがアカウントからのログアウトを申請していることを確認するために、UniLiveはユーザーと応募者のアイデンティティ情報を収集して検証し、ユーザーの権益を保護するために最善を尽くします。UniLiveは、個人情報保護に関する法律や規制を厳格に遵守しており、上記の情報は今回のアカウントのログアウトにのみ使用されます。

		</p>

		<p>
			UniLiveは、アカウントをログアウトする行為は不可逆的であることを特に示しています。法律法規に別途規定があるほか、アカウントをログアウトした後、私たちはあなたに関する個人情報を削除または匿名化します。もし個人情報を削除することが技術的に難しい場合、私たちは保存と必要なセキュリティ保護措置をとる以外の処理を停止します。
		</p>

		<p>（6）故人のアカウント権益</p>

		<p>

			もしUniLiveユーザーが不幸にも逝去した場合、ログアウトしていないアカウントは使用権者が亡くなった後に追悼アカウントになることができ、UniLiveは追悼アカウントのユーザー個人情報の安全を守り続ける。故人の近親者は本プライバシーポリシーの「どのように私たちに連絡するか」を明記した方法で私たちに連絡することができ、故人の生前の手配に違反せず、他人と公衆の正当な利益を侵害しない前提の下で、身分検査手続きを完了した後、故人の個人情報を閲覧、複製、訂正、削除して、法律法規に規定された合法的、正当な権益を行使することができる。

		</p>




		<h3>五、未成年者保護</h3>

		<p>

			私たちは国の関連法律法規の規定に基づいて未成年者の個人情報を保護し、法律の許可、両親またはその他の保護者が未成年者の個人情報を明確に同意したり保護したりするために必要な場合にのみ、未成年者の個人情報を収集、使用、共有、移転、公開します。事前に確認可能な親や他の保護者の同意を得ずに未成年者の個人情報が収集されていることが判明すると、関連情報が削除されます。また、子供（14歳未満）と青少年（14歳未満、18歳未満）の個人情報の安全を保護するために、厳格な未成年者情報収集使用規則を確立しました。

			未成年者の保護者である場合は、保護している未成年者の個人情報に関する疑問がある場合は、本プライバシーポリシーの「連絡先」に記載されている方法で連絡してください。

		</p>




		<h3>六、プライバシーポリシーアクセスと更新</h3>

		<p>（一）アクセスブックプライバシーポリシー</p>

		<p>1.最初にUniLiveアカウントに登録する際に、このプライバシーポリシーを提示し、同意するかどうかを自分で決定します。</p>


		<p>（二）本プライバシーポリシーの更新</p>

		<p>
			1.UniLiveは、本プライバシーポリシーを適時に改訂する可能性があります。お客様の明確な同意を得ず、または法律法規の関連規定に合致していない限り、本プライバシーポリシーに従ってお客様が享受すべき権利を削減することはありません。
		</p>

		<p>2.UniLiveプライバシーポリシーが更新されると、UniLiveはログイン時にこのプライバシーポリシーの最新コンテンツをタイムリーに理解できるように、サイトレターまたはその他の適切な方法で最新バージョンをお見せします。
		</p>

		<p>3.重大な変更については、顕著な通知（メールボックス、私信、システム通知、ポップアップ）を提供します。</p>



		<h3>七、私たちに連絡する方法</h3>

		<p>私たちは専門の個人情報保護チームと責任者を設立しました。</p>
		<p>個人情報保護に関するご質問、ご意見、ご提案があれば、次のいずれかの方法でお問い合わせください。</p>

		<p>1. メールの送信先：unilive.team@gmail.com</p>

		<p>UniLiveはできるだけ早く問題をレビューし、アイデンティティを検証した後、すぐに回答します。一般的には、15営業日以内にご要望にお答えします。</p>

	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>